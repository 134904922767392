const categoryMap: Record<string, string> = {
  'driving': 'data.vehicle.equipments.driving.label',
  'life_on_board': 'data.vehicle.equipments.life_on_board.label',
  'kitchen_meal': 'data.vehicle.equipments.kitchen_meal.label',
  'toilet': 'data.vehicle.equipments.toilet.label',
  'outside': 'data.vehicle.equipments.outside.label',
  'autonomy-details': 'data.vehicle.equipments.autonomy_details.label',
  'inside': 'data.vehicle.equipments.inside.label',
  'safety': 'data.vehicle.equipments.safety.label',
  'winter': 'data.vehicle.equipments.winter.label',
}

export const useGetTranslatedVehicleEquipmentCategory = () => {
  const { $i18n: { t } } = useNuxtApp()
  return {
    getTranslatedVehicleEquipmentCategory: (category: string | null | undefined) => {
      if (!category) {
        return null
      }

      const key = categoryMap[category]

      return key ? t(key) : category
    },
  }
}
