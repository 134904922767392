const equipmentMap: Record<string, string> = {
  automatic_gear: 'components.app_search.filters.automatic_gear',
  power_steering: 'data.vehicle.equipments.driving.options.power_steering',
  cruise_control: 'data.vehicle.equipments.driving.options.cruise_control',
  four_wheel_drive: 'data.vehicle.equipments.driving.options.four_wheel_drive',
  gps: 'data.vehicle.equipments.driving.options.gps',
  parking_aid_radar: 'data.vehicle.equipments.driving.options.parking_aid_radar',
  parking_aid_camera: 'data.vehicle.equipments.driving.options.parking_aid_camera',
  autoradio: 'data.vehicle.equipments.driving.options.autoradio',
  cd_player: 'data.vehicle.equipments.driving.options.cd_player',
  bluetooth: 'data.vehicle.equipments.driving.options.bluetooth',
  ipod_input: 'data.vehicle.equipments.driving.options.ipod_input',
  heating: 'data.vehicle.equipments.driving.options.heating',
  ac: 'data.vehicle.equipments.driving.options.ac',
  power_locked: 'data.vehicle.equipments.driving.options.power_locked',
  baby_seat: 'data.vehicle.equipments.driving.options.baby_seat',
  child_seat: 'data.vehicle.equipments.driving.options.child_seat',
  winter_tire: 'data.vehicle.equipments.driving.options.winter_tire',
  winter_chain: 'data.vehicle.equipments.driving.options.winter_chain',
  wedge: 'data.vehicle.equipments.driving.options.wedge',
  security_pack: 'data.vehicle.equipments.driving.options.security_pack',
  tv: 'data.vehicle.equipments.life_on_board.options.tv',
  dvd_player: 'data.vehicle.equipments.life_on_board.options.dvd_player',
  satellite: 'data.vehicle.equipments.life_on_board.options.satellite',
  cleaning_kit: 'data.vehicle.equipments.life_on_board.options.cleaning_kit',
  consummables: 'data.vehicle.equipments.life_on_board.options.consummables',
  ac_premium: 'data.vehicle.equipments.life_on_board.options.ac_premium',
  heating_premium: 'data.vehicle.equipments.life_on_board.options.heating_premium',
  bedding: 'data.vehicle.equipments.life_on_board.options.bedding',
  swivel_seat_driver: 'data.vehicle.equipments.life_on_board.options.swivel_seat_driver',
  swivel_seat_passenger: 'data.vehicle.equipments.life_on_board.options.swivel_seat_passenger',
  extinguisher: 'data.vehicle.equipments.life_on_board.options.extinguisher',
  sink: 'data.vehicle.equipments.kitchen_meal.options.sink',
  cooking: 'data.vehicle.equipments.kitchen_meal.options.cooking',
  oven: 'data.vehicle.equipments.kitchen_meal.options.oven',
  fridge: 'data.vehicle.equipments.kitchen_meal.options.fridge',
  freezer: 'data.vehicle.equipments.kitchen_meal.options.freezer',
  extractor: 'data.vehicle.equipments.kitchen_meal.options.extractor',
  cookware: 'data.vehicle.equipments.kitchen_meal.options.cookware',
  coffee_maker: 'data.vehicle.equipments.kitchen_meal.options.coffee_maker',
  table: 'data.vehicle.equipments.kitchen_meal.options.table',
  dishes_kit: 'data.vehicle.equipments.kitchen_meal.options.dishes_kit',
  shower_int: 'data.vehicle.equipments.toilet.options.shower_int',
  shower_ext: 'data.vehicle.equipments.toilet.options.shower_ext',
  wc: 'data.vehicle.equipments.toilet.options.wc',
  wc_portable: 'data.vehicle.equipments.toilet.options.wc_portable',
  bathroom_sink: 'data.vehicle.equipments.toilet.options.bathroom_sink',
  bicycle: 'data.vehicle.equipments.outside.options.bicycle',
  side_blind: 'data.vehicle.equipments.outside.options.side_blind',
  camping_table: 'data.vehicle.equipments.outside.options.camping_table',
  barbecue: 'data.vehicle.equipments.outside.options.barbecue',
  towbar: 'data.vehicle.equipments.outside.options.towbar',
  disabled_access: 'data.vehicle.equipments.outside.options.disabled_access',
  sout_luggage: 'data.vehicle.equipments.outside.options.sout_luggage',
  sout_garage: 'data.vehicle.equipments.outside.options.sout_garage',
  sout_adjustable: 'data.vehicle.equipments.outside.options.sout_adjustable',
  solar: 'data.vehicle.equipments.autonomy_details.options.solar',
  electric_linking: 'data.vehicle.equipments.autonomy_details.options.electric_linking',
  electric_plug_adaptator: 'data.vehicle.equipments.autonomy_details.options.electric_plug_adaptator',
  auxiliary_battery: 'data.vehicle.equipments.autonomy_details.options.auxiliary_battery',
  generator: 'data.vehicle.equipments.autonomy_details.options.generator',
  meal_seats: 'data.vehicle.equipments.meal_seats.label',
  dishes_count: 'data.vehicle.equipments.dishes_count.label',
  chair_count: 'data.vehicle.equipments.chair_count.label',
  bicycle_count: 'data.vehicle.equipments.bicycle_count.label',
  autonomy: 'data.vehicle.equipments.autonomy.label',
  cooking_energies: 'data.vehicle.equipments.cooking_energies.label',
  solar_count: 'data.vehicle.equipments.solar_count.label',
  heating_energies: 'data.vehicle.equipments.heating_energies.label',
  electric_plug_types: 'data.vehicle.equipments.electric_plug_types.label',
  fridge_energies: 'data.vehicle.equipments.fridge_energies.label',
  others: 'data.vehicle.equipments.others.label',
  airbags: 'data.vehicle.equipments.driving.options.airbags',
  l_seat: 'data.vehicle.equipments.kitchen_meal.options.l_seat',
  safety_box: 'data.vehicle.equipments.life_on_board.options.safety_box',
  winter_camping: 'data.vehicle.equipments.life_on_board.options.winter_camping',
  roof_rack: 'data.vehicle.equipments.outside.options.roof_rack',
  hot_water: 'data.vehicle.equipments.toilet.options.hot_water',
  towels: 'data.vehicle.equipments.toilet.options.towels',
  isofix: 'components.app_search.filters.isofix',
}

export const useGetTranslatedVehicleEquipment = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedVehicleEquipment: (equipment: string | null | undefined) => {
      if (!equipment) {
        return null
      }
      equipment = equipment.toLowerCase()

      return equipmentMap[equipment] ? t(equipmentMap[equipment]) : equipment
    },
  }
}
